import makeStyles from '@mui/styles/makeStyles';
import { cssVar } from 'utils/css';

export const useCheckboxStyles = makeStyles({
  radio: {
    margin: '0.0625rem 0.5rem 0 0',
  },
  formControlLabel: {
    alignItems: 'flex-start',
    display: 'flex',
    marginLeft: '0',
    marginRight: '0',
    '&:not(:last-child)': {
      marginBottom: '0.75rem',
    },
  },
  typographyTitle: {
    color: cssVar('--color-mine-shaft'),
    fontWeight: 600,
  },
});
