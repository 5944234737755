import { useState, useRef, useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography,
  Button,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { Skeleton } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { SiteDetail, SitePaginationState, useSites, useUserSites } from 'api/site';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'debounce';
import { cssVar } from 'utils/css';

const useStyles = makeStyles(theme => ({
  dropdownHeading: {
    margin: '0.3125rem 0',
    maxWidth: '100%',
    textAlign: 'left',
    '& h1': {
      maxWidth: '28.125rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  dropdownButton: {
    width: '1.125rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingBottom: 0,
    '& svg': {
      width: '0.875rem',
      height: 'auto',
      color: cssVar('--color-mine-shaft'),
    },
  },
  sitesDropdownHeader: {
    backgroundColor: cssVar('--color-ebb'),
  },
  sitesDropdownOverflow: {
    overflowX: 'auto',
    padding: '0',
  },
  whiteInput: {
    backgroundColor: cssVar('--color-white'),
  },
  selectedSite: {
    cursor: 'pointer',
    '& td': {
      color: 'var(--color-curious-blue) !important',
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

export default function RowsSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

export function SiteSelectorMenu({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails: SiteDetail | undefined;
  readonly isWpSiteControl: boolean;
}) {
  const [showSiteDropdown, setShowSiteDropdown] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const {
    dropdownButton,
    dropdownHeading,
    selectedSite,
    sitesDropdownHeader,
    sitesDropdownOverflow,
    tableRow,
    whiteInput,
  } = useStyles();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<SitePaginationState>({
    perPage: 10,
    activePageNumber: 1,
    filter: '',
    sortAttr: 'domain',
    sortOrder: 'asc',
  });
  const sitesData = useSites(pagination, { enabled: showSiteDropdown && !isWpSiteControl });
  const userSitesData = useUserSites(pagination, { enabled: showSiteDropdown && isWpSiteControl });
  const { data, refetch, status } = isWpSiteControl ? userSitesData : sitesData;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    refetch();
  }, [pagination.filter]);

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowsSkeleton />;
    }

    return data?.data.result?.map(site => {
      return (
        <TableRow
          key={site.id}
          className={siteDetails?.domain === site.domain ? selectedSite : tableRow}
          onClick={() => {
            navigate(pathname.replace(String(siteDetails?.id ?? ''), String(site.id)));
          }}
        >
          <TableCell>{site.domain}</TableCell>
          <TableCell>{site.label}</TableCell>
          <TableCell>
            {siteDetails?.domain === site.domain ? <FontAwesomeIcon icon={faCheck} /> : null}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <Button
        className={dropdownHeading}
        aria-describedby="siteSelectorPopper"
        ref={anchorRef}
        onClick={() => {
          setShowSiteDropdown(!showSiteDropdown);
        }}
      >
        {
          <>
            <Typography variant="h1" data-testid="domainLabel" marginRight={1} textAlign="center">
              {siteDetails?.domain ?? ''}
            </Typography>
            <span className={dropdownButton}>
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </>
        }
      </Button>
      <Popper
        id="siteSelectorPopper"
        open={showSiteDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        style={{ zIndex: 1, width: '28.125rem', maxWidth: 'calc(100% - 2rem)' }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setShowSiteDropdown(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Box padding={1} className={sitesDropdownHeader}>
                  <TextField
                    onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                      setPagination({
                        ...pagination,
                        activePageNumber: 1,
                        filter: event.target.value,
                      });
                    }, 700)}
                    variant="outlined"
                    placeholder={String(t('site_change_by_domain_label'))}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" disableTypography component="button">
                          <SearchRounded />
                        </InputAdornment>
                      ),
                      className: whiteInput,
                    }}
                    fullWidth
                  />
                </Box>
                <Box padding={1} className={sitesDropdownOverflow}>
                  <Table aria-label="Site List Table">
                    <TableBody>
                      {pagination.filter !== '' && data?.data.result?.length === 0 ? (
                        <NoResultsFound colSpan={3} />
                      ) : (
                        renderTableRows()
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}
